import { useEffect } from "react";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { analytics } from "../../analytics";
import { useLocationWrapper } from "../../hooks/useLocationWrapper";

import UnsupportedManufacturerLogo from "../../assets/unsupported-manufacturer-logo.svg";
import Button from "../ButtonComponent";
import { useGetNextState } from "../../hooks/useGetNextState";
import { useParams } from "react-router";
import { Manufacturer } from "../../interfaces";
import { FEATURE_FLAGS } from "../../constants";


// we are missing the device name, display_name prop or something here to be used in the title
interface IState extends Manufacturer { }

const apiUrl = import.meta.env.VITE_CONNECT_API_BASE_URL || "http://localhost:3000";

export function ConnectUnsupported() {
  const { linkToken = "" } = useParams();
  const { state } = useLocationWrapper<IState>();
  const { getNextState } = useGetNextState(linkToken);

  const threeCECopyEnabled = useFeatureIsOn(FEATURE_FLAGS.threeCECopyEnabled);

  useEffect(() => {
    if (analytics && analytics.page) {
      analytics.page("connect unsupported");
    }

    const storeUnsupportedManufacturer = async () => {
      // make call here to send the unsupported manufacturer to the server
      try {
        await fetch(`${apiUrl}/v1/states/${linkToken}/unsupportedManufacturer/${state.slug}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });
      } catch (err) {
        console.error(err)
      }
    };

    storeUnsupportedManufacturer();

  }, []);

  const handleRepeat = () => {
    getNextState("success", {
      type: "continue",
    });
  };

  return (
    <>
      <div className="hidden tp:block d:block"></div>
      <div className="flex flex-col items-center justify-between gap-8 tp:gap-4 max-w-[40rem]">
        <img src={UnsupportedManufacturerLogo} className="mx-auto w-24 h-24" alt="success-logo" />
        <div className="text-texturePennBlue font-bold text-xl d:text-4xl tp:text-2xl text-center">
        Support for { state.displayName } Coming Soon!
        </div>
        { threeCECopyEnabled ? (
          <div>
            <div className="text-texturePennBlue text-center mb-5">
              Texture does not currently support { state.displayName } devices. Please note, this does not disqualify you from 3CE’s rebate. Staff are reviewing your application now and will reach out with next steps.
            </div>
            <div className="text-texturePennBlue text-center mt-20">
              <a className="text-textureIris cursor-pointer" target="_blank" href="https://3cenergy.org/rebates-incentives/">Explore 3CE's Energy Programs</a>
            </div>
          </div>
        ) : (
          <div>
            <div className="text-texturePennBlue text-center mb-5">
              Thank you for choosing { state.displayName }. We'll notify you as soon as this manufacturer is fully supported to connect your devices.{" "}
            </div>
            <div className="text-texturePennBlue text-center mb-5">
              If you have any questions, please <a className="text-textureIris cursor-pointer" target="_blank" href="https://www.texturehq.com/contact-us">reach out to us</a>.
            </div>
            <div className="text-texturePennBlue text-center mb-5">
              Thank you for your feedback!
            </div>
          </div>
        )}
      </div>
      { !threeCECopyEnabled &&
        <div className="w-full sm:w-[30rem] flex flex-col items-center gap-8 tp:gap-4">  
          <Button onClick={handleRepeat} link secondary>
            Connect another device
          </Button>
        </div>
      }
    </>
  );
}
