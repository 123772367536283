import { useParams, useSearchParams } from "react-router";
import { useEffect } from "react";
import { ConnectLoading } from "./ConnectLoading";
import { useGetNextState } from "../../hooks/useGetNextState";
import { SESSION_STORAGE_KEYS, sessionStorageService } from "../../services/sessionStorage";

export function ConnectStart() {
  const { linkToken } = useParams();
  const { getNextState } = useGetNextState(linkToken || "");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const origin = searchParams.get("origin");

    if (origin) {
      sessionStorageService.set(SESSION_STORAGE_KEYS.hostOrigin, origin);
    }
  }, [searchParams]);

  useEffect(() => {
    getNextState("start", {
      type: "continue",
    });
  }, []);

  return <ConnectLoading />;
}
