import { useEffect, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router";
import { useGetNextState } from "../../hooks/useGetNextState";
import { ConnectLoading } from "./ConnectLoading";
import { analytics } from "../../analytics";
import { SESSION_STORAGE_KEYS, sessionStorageService } from "../../services/sessionStorage";

export function ConnectOAuth() {
  const { linkToken } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { getNextState } = useGetNextState(linkToken || "");
  const exchangeCodeOnce = useRef(false);

  useEffect(() => {
    if (analytics && analytics.page) {
      analytics.page("connect oauth");
    }
  }, []);

  const code = searchParams.get("code");
  const state = searchParams.get("state");

  console.log("oauth state", state);

  // exchange oauth code for access + refresh token
  useEffect(() => {
    if (!code) {
      navigate(`/${linkToken}/error`);
      return;
    }

    const storedState = sessionStorageService.get(SESSION_STORAGE_KEYS.state);
    if (storedState !== state) {
      navigate(`/${linkToken}/error`);
      return;
    }

    /**
     * Avoid multiple calls to exchange code
     * This is primarily an issue that occurs with react strict mode
     * where useEffect is called twice
     */
    if (exchangeCodeOnce.current) {
      return;
    }
    exchangeCodeOnce.current = true;

    getNextState("oauth:code", {
      type: "continue",
      data: {
        code,
      },
    });
  }, []);

  return <ConnectLoading />;
}
