import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useLocationWrapper } from "./useLocationWrapper";
import { SESSION_STORAGE_KEYS, sessionStorageService } from "../services/sessionStorage";

/**
 * Right now at least, we don't want to allow deep linking into the app.
 *
 * At least you first need to go through the root route, which can jump
 * to the appropriate starting page.
 *
 * This hook will ensure that there is a previous session before jumping to
 * a deep linked page. Not perfect, but should alleviate issues where the
 * link is copy/pasted to a user who has never used the connect flow before.
 */
export function usePreventDeepLink() {
  const { pathname } = useLocationWrapper();
  const { linkToken } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const hasSessionStarted = sessionStorageService.get(SESSION_STORAGE_KEYS.sessionStarted);

    if (hasSessionStarted) {
      return;
    }

    const isDeepLink = pathname.slice(1) !== linkToken;
    sessionStorageService.set(SESSION_STORAGE_KEYS.sessionStarted, "true");

    if (isDeepLink) {
      navigate(`/${linkToken}`, {
        replace: true,
      });
    }
  }, []);
}
