import { useEffect, useState } from "react";
import GreenCheckLogo from "../../assets/green-check-logo.svg";
import { useSearchParams } from "react-router";

export function ConnectUtility() {
    const [connected, setConnected] = useState(false);
    const [searchParams] = useSearchParams();

    const origin = window.origin;
    const correlationId = searchParams.get("correlationId") || "user123";
    const mode = searchParams.get("mode") || "live";

    useEffect(() => {
        const messageListener = (event: MessageEvent) => {
            if (event.origin !== 'https://arc-connect.arcadia.com') {
                return;
            }

            const { type, payload } = event.data;
            console.log(type, payload);

            switch (type) {
                case "onStart":
                    break;
                case "onCredentialsSubmitted":
                    break;
                case "onEnd":
                    setConnected(true);
                    break;
                default:
                    break;
            }
        }

        window.addEventListener("message", messageListener);

        return () => {
            window.removeEventListener("message", messageListener);
        };
    }, [])

    if (connected) {
        return <>
            <div className="hidden tp:block d:block"></div>
            <div className="flex flex-col items-center justify-between gap-8 tp:gap-4 max-w-[40rem]">
                <img src={GreenCheckLogo} className="mx-auto w-24 h-24" alt="success-logo" />
                <div className="text-texturePennBlue font-bold text-xl d:text-4xl tp:text-2xl text-center">
                    Your utility account has successfully been connected.
                </div>
            </div>
        </>
    }

    return (
        <>
            <iframe src={`https://arc-connect.arcadia.com/yIL4CHwtwRznFQcxnv16?origin=${origin}&correlationId=${correlationId}&apiMode=${mode}`} width="800" height="800"></iframe>
        </>
    );
}
